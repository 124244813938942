<template>
  <van-image class="application-step-item-icon" :src="fromOperationGetIcon(operation)"></van-image>
</template>

<script>
import applicationErrorIcon from "@/assets/images/application_error.svg";
import applicationSuccessIcon from "@/assets/images/application_success.svg";
import applicationWaitIcon from "@/assets/images/application_wait.svg";
import applicationUnreachedIcon from "@/assets/images/application_unreached.svg";
import {Operation} from "@/api/consts";

export default {
  name: "OperationIcon",
  props: {
    operation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      applicationErrorIconValue: applicationErrorIcon,
      applicationSuccessIconValue: applicationSuccessIcon,
      applicationWaitIconValue: applicationWaitIcon,
      applicationUnreachedIconValue: applicationUnreachedIcon,
    };
  },
  methods: {
    fromOperationGetIcon(operation) {
      switch (operation) {
        case Operation.SUBMIT:
          return this.applicationSuccessIconValue;
        case Operation.AGREE:
          return this.applicationSuccessIconValue;
        case Operation.REJECT:
          return this.applicationErrorIconValue;
        case Operation.UNAPPROVED:
          return this.applicationWaitIconValue;
        case Operation.UN_REACHED:
          return this.applicationUnreachedIconValue;
        case Operation.SYSTEM_AUTO_REJECT:
          return this.applicationErrorIconValue;
        case Operation.CANCEL:
          return this.applicationErrorIconValue;
        case Operation.DATE_CHANGED:
          return this.applicationSuccessIconValue;
        case Operation.REAPPLY:
          return this.applicationSuccessIconValue;
        default:
          return "";
      }
    },
  },
}

</script>

<style scoped>

</style>
