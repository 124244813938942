<template>
  <div v-if="applicationValue.process !== undefined && applicationValue.process !== null">
    <div v-if="applicationValue.process.length>0">
      <van-steps :active="currentProcessActive" direction="vertical">
        <van-step v-for="(item,index) in applicationValue.process" :key="index"
                  :description="item.remarks"
                  :class="{
                    'application-step-item-container-unreached': judgeNextItemIsUnreached(index),
                    // 'application-step-item-container-next-step-reject': judgeNextItemIsRejected(index),
        }">
          <template v-slot:active-icon>
            <operation-icon class="application-step-item-icon" :operation="item.operationStatus"/>
          </template>
          <template v-slot:inactive-icon>
            <operation-icon class="application-step-item-icon" :operation="item.operationStatus"/>
          </template>
          <template v-slot:finish-icon>
            <operation-icon class="application-step-item-icon" :operation="item.operationStatus"/>
          </template>
          <div class="application-step-item-title-container">
            <div class="title">{{ index === 0 ? '发起申请' : item.step }}</div>
            <div class="time" v-if="item.operationTime">{{ fromMillisecondGetDescription(item.operationTime) }}</div>
          </div>
          <div class="application-step-item-content">
            {{
              item.operators ? item.operators.join("、") : ""
            }}{{ item.action ? " " + item.action : "" }}{{ item.actionResult ? " " + item.actionResult : "" }}
          </div>
          <div class="application-step-item-remark" v-if="item.remarks">
            {{ "备注：" + item.remarks }}
          </div>
        </van-step>
      </van-steps>
    </div>


  </div>
</template>

<script>
import StateIcon from "@/components/Apply/ApplyStateIcon.vue";
import dayjs from "dayjs";
import applicationErrorIcon from "@/assets/images/application_error.svg";
import applicationSuccessIcon from "@/assets/images/application_success.svg";
import applicationWaitIcon from "@/assets/images/application_wait.svg";
import applicationUnreachedIcon from "@/assets/images/application_unreached.svg";
import {Operation} from "@/api/consts";
import OperationIcon from "@/components/common/OperationIcon.vue";
import {fromMillisecondGetDescription} from "@/api/utils/utils";

export default {
  components: {OperationIcon},
  props: {
    applicationValue: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      applicationErrorIconValue: applicationErrorIcon,
      applicationSuccessIconValue: applicationSuccessIcon,
      applicationWaitIconValue: applicationWaitIcon,
      applicationUnreachedIconValue: applicationUnreachedIcon,
    };
  },
  computed: {
    Operation() {
      return Operation
    },
    currentProcessActive: function () {
      // this.applicationValue.process;
      // 遍历找到operation!== AGREE的
      var activeValue = 0;
      for (var i = 0; i < this.applicationValue.process.length; i++) {
        if (this.applicationValue.process[i].operationStatus !== Operation.AGREE) {
          activeValue = i;
          break;
        }
      }
      return activeValue;
    },
  },
  methods: {
    fromMillisecondGetDescription(milliseconds) {
      return fromMillisecondGetDescription(milliseconds);
    },
    dayjs,
    timeFormat(t) {
      return dayjs(t).format("YYYY-MM-DD HH:mm:ss");
    },
    getOperationName(operation) {
      return Operation.getOperationName(operation);
    },
    judgeNextItemIsUnreached(index) {
      if (index === this.applicationValue.process.length - 1) {
        return false;
      }
      return this.applicationValue.process[index + 1].operationStatus === Operation.UN_REACHED;
    },
    judgeNextItemIsRejected(index) {
      if (index === this.applicationValue.process.length - 1) {
        return false;
      }
      return this.applicationValue.process[index + 1].operationStatus === Operation.REJECT
          || this.applicationValue.process[index + 1].operationStatus === Operation.SYSTEM_AUTO_REJECT
          || this.applicationValue.process[index + 1].operationStatus === Operation.CANCEL
          ;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-step--vertical:not(:last-child)::after {
  border-bottom-width: 0;
}

/deep/ .van-step__circle {
  width: 0.55rem;
  height: 0.55rem;
}

/deep/ .van-step__icon {
  width: 0.6rem;
  height: 0.6rem;

  &::before {
    font-size: 0.6rem;
  }
}

/deep/ .van-step__line {
  width: 0.1rem;
  left: -0.49rem;
  background-color: #e5e5e5;
}

.application-step-item-container-unreached:not(:last-child) {
  /deep/ .van-step__line {
    width: 0.1rem;
    left: -0.49rem;
    background-color: transparent;
    // 虚线
    border-left: 0.1rem dashed #e5e5e5;
  }
}

.application-step-item-container-next-step-reject {
  /deep/ .van-step__line {
    width: 0.1rem;
    left: -0.49rem;
    background-color: #e14440;
  }
}

/deep/ .van-steps {
  background-color: transparent;
}

.application-step-item-title-container {
  // 左右布局
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 13px;
    color: var(--subTitleColor);
  }

  .time {
    font-size: 12px;
    color: var(--subTitleColor);
  }
}

.application-step-item-content {
  font-size: 17px;
  color: var(--titleColor);
  font-weight: 500;
  margin-top: 2px;
}

.application-step-item-icon {
  width: 0.6rem;
  height: 0.6rem;
}

.application-step-item-remark {
  // 换行
  word-break: break-all;
  margin-top: 0.1rem;
  font-size: 13px;
  color: var(--mainColor);
}
</style>
