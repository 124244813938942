<script>

import {IconType} from "@/api/consts";

export default {
  props: ["type", "size"],
  render() {
    //  static DONE_OR_CURRENT = "1";
    // static REJECTED = "2";
    // static ABLE_TO_VIEW_APPROVE = "3";
    // static REACHABLE = "4";
    // static NOT_ALLOWED = "5";
    // static DONE_BUT_NOT_REACHED = "6";
    console.log("icon type：" + this.type);
    const size = this.size || 18;
    if (this.type === IconType.DONE_OR_CURRENT) {
      return <van-icon style="font-size: 18px" name="checked" color="#11A70E" size={size}/>;
    } else if (this.type === IconType.REJECTED) {
      return <van-icon name="clear" color="var(--red)" size={size}/>;
    } else if (this.type === IconType.ABLE_TO_VIEW_APPROVE) {
      return (
          <span class="right-arrow">
                    <van-icon name="down" color="#fff" size={size - 6}/>
                </span>
      );
    } else if (this.type === IconType.REACHABLE) {
      return (
          <span class="border" style={`height: ${size - 1}px; width: ${size - 1}px`}></span>
      );
    } else if (this.type === IconType.NOT_ALLOWED) {
      return (
          <span
              class="border disabled"
              style={`height: ${size - 1}px; width: ${size - 1}px`}
          ></span>
      );
    } else if (this.type === IconType.DONE_BUT_NOT_REACHED) {
      return (
          <span class="right-arrow-dont-but-not-reached">
                    <van-icon name="down" color="#fff" size={size - 6}/>
                </span>
      );
    } else {
      return <span></span>;
    }
  },
};
</script>

<style lang="less" scoped>
.right-arrow {
  width: 17px;
  height: 17px;
  background-color: #205af9;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;

  .van-icon {
    transform: rotate(-90deg);
  }
}

.right-arrow-dont-but-not-reached {
  width: 17px;
  height: 17px;
  background-color: #11a70e;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;

  .van-icon {
    transform: rotate(-90deg);
  }
}

.border {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1.5px solid #ccc;
  background-color: #fff;
}

.disabled {
  background-color: var(--separatorColor);
}
</style>
