<template>
  <div class="approval-record-page-content" v-if="firstInitFinished">
    <template v-if="Object.keys(this.approvalRecordListGrouped).length>0">
      <!--      遍历approvalRecordListGrouped这个字典-->
      <template v-for="(value,key,index) in this.approvalRecordListGrouped">
        <div class="day_group" :key="index">
          <div class="title">{{ key }}</div>
          <div class="record_list">
            <template v-for="(item,approvalRecordIndexInGroup) in value">
              <div class="approval_record_item" :key="approvalRecordIndexInGroup"
                   @click="item.applicationType !== 4
                   ?$router.push(`/hoss/application?clientId=${clientId}&applicationType
                   =${item.applicationType}&applicationId=${item.applicationId}`)
                   :$router.push(`/hoss/fanGroupReport?id=`+item.resource.id+`&milepostId=`+item.resource.milepostId)">
                <div class="contraction">
                  <div class="item_header_group">
                    <div class="item_title_group">
                      <div class="item_title">{{
                          {4: "粉丝群报告申请", 3: "终止申请", 2: "结单申请", 1: "转二阶段申请"}[item.applicationType]
                        }}
                      </div>
                      <operation-icon class="item_title_state_icon"
                                      :operation="item.currentOperationStatus"/>
                    </div>
                    <!--                    <van-icon name="arrow-down" class="arrow_down"/>-->
                    <!--                    点击事件是onClickExpendedOrNotIcon，当approvalRecordListGrouped包含item.applicationId时是下箭头，否则上箭头-->
                    <van-icon name="arrow-down"
                              @click.stop="onClickExpendedOrNotIcon(item.applicationId)"
                              :class="expendedList.indexOf(item.applicationId) !== -1 ? 'arrow_up' : 'arrow_down'"/>
                  </div>

                  <div class="item_subtitle">
                    {{ "最后更新于：" + fromMillisecondGetDescription(item.lastOperateTime) }}
                  </div>
                </div>
                <div class="expended_detail"
                     :class="expendedList.indexOf(item.applicationId) !== -1 ? 'expended' : 'hide'"
                     :style="
                expendedList.indexOf(item.applicationId) !== -1
                  ? 'max-height: ' + (item.process.length * 2.4 + 2) + 'rem'
                  : 'max-height: 0'
"
                >
                  <div style="font-size: 17px;font-weight: 500;margin-bottom: 0.3rem">流程</div>
                  <Process :application-value="item" style="margin-left: -0.18rem"/>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <van-empty description="审批记录为空"/>
    </template>
  </div>
</template>

<script>
import {getApprovalList} from "@/api/hoss";
import dayjs from "dayjs";
import StateIcon from "@/components/Apply/ApplyStateIcon.vue";
import Process from "@/components/Apply/Process";
import OperationIcon from "@/components/common/OperationIcon.vue";
import {Operation} from "@/api/consts";
import {Empty} from "vant";
import {fromMillisecondGetDescription} from "@/api/utils/utils";

export default {
  components: {OperationIcon, Process},
  data() {
    return {
      // key是日期，value是审批记录列表
      approvalRecordListGrouped: {},
      expendedList: [],
      clientId: null,
      firstInitFinished: false,
    };
  },
  created() {
    this.init();
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.$nextTick(() => {
      this.init();
    });
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  methods: {
    fromMillisecondGetDescription(millisecond) {
      return fromMillisecondGetDescription(millisecond);
    },
    async init() {
      const {clientId} = this.$route.query;
      this.clientId = clientId;
      if (clientId) {
        const {value} = await getApprovalList({
          clientId,
        });
        if (value) {
          // 按日期分组，reportTime是时间戳 毫秒，字典内key是日期，value是审批记录列表，key按照日期从近到远排序
          this.approvalRecordListGrouped = value.reduce((acc, cur) => {
            const key = dayjs(cur.reportTime).format("YYYY-MM-DD");
            if (acc[key]) {
              acc[key].unshift(cur);
            } else {
              acc[key] = [cur];
            }
            return acc;
          }, {});
          this.approvalRecordListGrouped = Object.keys(this.approvalRecordListGrouped)
              .sort((a, b) => {
                return dayjs(b).valueOf() - dayjs(a).valueOf();
              })
              .reduce((acc, cur) => {
                acc[cur] = this.approvalRecordListGrouped[cur];
                return acc;
              }, {});
        }
      }
      this.firstInitFinished = true;
    },
    onClickExpendedOrNotIcon(applicationId) {
      if (this.expendedList.includes(applicationId)) {
        this.expendedList = this.expendedList.filter((item) => item !== applicationId);
      } else {
        this.expendedList.push(applicationId);
      }
      console.log(this.expendedList);
    },
    approvalResult2Operation(approvalResult) {
      // 0-待审核 1-通过 2-拒绝 3-系统自动终止
      switch (approvalResult) {
        case 0:
          return Operation.UNAPPROVED;
        case 1:
          return Operation.AGREE;
        case 2:
          return Operation.REJECT;
        case 3:
          return Operation.SYSTEM_AUTO_REJECT;
        default:
          return Operation.UNAPPROVED;
      }
    },
    close() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.approval-record-page-content {
  padding: 10px;

  // 日期分组的每个组
  .day_group {
    margin-bottom: 10px;

    // 日期
    .title {
      font-size: 17px;
      font-weight: bold;
      color: var(--titleColor);
    }

    // 日期分组下的审批记录列表
    .record_list {
      margin-top: 10px;

      // 审批记录item卡片
      .approval_record_item {
        // 10px 白色圆角背景
        background: var(--cardBackgroundColor);
        border-radius: 10px;
        margin-bottom: 10px;
        transition: all 0.25s;
        overflow: hidden;

        .contraction {
          padding: 13px 16px;
          // 审批记录item卡片的头部
          .item_header_group {
            display: flex;
            justify-content: space-between;
            align-items: center;

            // 审批记录item卡片的头部的左边
            .item_title_group {
              display: flex;
              align-items: center;

              // 审批记录item卡片的头部的左边的标题
              .item_title {
                font-size: 17px;
                color: var(--titleColor);
                letter-spacing: -0.41px;
              }

              // 审批记录item卡片的头部的左边的状态图标
              .item_title_state_icon {
                margin-left: 5px;
              }

            }

            // 审批记录item卡片的头部的右边的箭头图标
            // 变化时旋转
            .arrow_down {
              transition: transform 0.25s;
            }

            .arrow_up {
              transition: transform 0.25s;
              transform: rotate(180deg);
            }
          }

          // 审批记录item卡片的副标题
          .item_subtitle {
            font-size: 13px;
            color: var(--subTitleColor);
            margin-top: 6px;
          }
        }

        .expended_detail {
          // 审批记录item卡片的展开的详情 hide和expended
          // hide时高度为0
          // expended时高度为auto 由内容撑开 有过渡动画
          overflow: hidden;
          transition: all 0.25s;
          max-height: 0;

          &.expended {
            max-height: 450px;
            background-color: var(--secondCardBackgroundColor);
            // 内部内容的padding
            padding: 10px 16px;
          }

          &.hide {
            max-height: 0;
            padding: 0 16px;
          }

        }
      }
    }
  }
}
</style>
