import dayjs from "dayjs";

export function fromMillisecondGetDescription(millisecond) {
    // 今天、昨天、前天，其他显示日期yyyy-MM-dd
    const now = dayjs();
    const report = dayjs(millisecond);
    // 计算相差天数 越过24点就算一天
    const diffMillisecond = now.diff(report, "millisecond");
    // 计算report距离24点还有多少毫秒
    const reportTo24HourMillisecond = report.endOf("day").diff(report, "millisecond");
    // 计算相差天数
    const diff = diffMillisecond > reportTo24HourMillisecond ? now.diff(report, "day") + 1 : now.diff(report, "day")
    if (diff === 0) {
        return "今天 " + report.format("HH:mm:ss");
    } else if (diff === 1) {
        return "昨天 " + report.format("HH:mm:ss");
    } else if (diff === 2) {
        return "前天 " + report.format("HH:mm:ss");
    } else {
        return report.format("YYYY-MM-DD HH:mm:ss");
    }
}

export function fromSecondGetDescription(second) {
    return fromMillisecondGetDescription(second * 1000);
}

